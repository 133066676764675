<template>
    <v-app>
        <v-app-bar
            relative
            color="#6A76AB"
            dark
            src="https://picsum.photos/1920/1080?random"
            fade-img-on-scroll
            scroll-target="#scrolling-techniques-4"
            >
            <template v-slot:img="{ props }">
                <v-img
                v-bind="props"
                gradient="to top right, rgba(0,0,0), rgba(25,32,72,.7)"
                ></v-img>
            </template>

            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

            <v-toolbar-title>AUDIDOC</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn @click="$vuetify.theme.dark=!$vuetify.theme.dark" fab text small>
                <v-icon> mdi-brightness-4 </v-icon>
            </v-btn>
            <v-btn text fab small>
                <Perfil />
            </v-btn>
            <!--
            <v-btn text fab small>
                <BuscarInformacionEmpresa/>
            </v-btn>
            -->
            <v-dialog v-if="permiso_buscador"  v-model="buscador">
              <template v-slot:activator="{ on, attrs }">
                  <v-tooltip v-on="on" v-bind="attrs">
                    <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                small
                                fab
                                text
                                color="white darken-4"
                                v-on="on"
                                v-bind="attrs"
                                @click="buscador = !buscador"
                            >
                            <v-icon>mdi-clipboard-text-search</v-icon>
                        </v-btn>
                    </template> 
                     <span>Buscador</span>
                </v-tooltip>  
              </template>  
                <Buscador>
                    <template slot="accion">
                        <v-btn @click="buscador=false" icon fab text small> <v-icon>mdi-close</v-icon> </v-btn>
                    </template>
                </Buscador>
            </v-dialog>

            <!---
            <v-btn fab text small color="indigo" @click="calendar = !calendar">
                <v-icon> mdi-calendar</v-icon>
            </v-btn>
            -->
            <!---
            <v-btn
                fab
                text
                small
                class="mr-2"
                color="yellow"
                v-if="foldersUser.length != 0"
                @click="notificacion = !notificacion"
            >
                <v-badge color="red" dot>
                <v-icon>mdi-bell</v-icon>
                </v-badge>
            </v-btn>
           

            <v-btn
                fab
                text
                small
                class="mr-4"
                color="yellow"
                @click="notificacion = !notificacion"
                v-else
            >
                <v-icon>mdi-bell</v-icon>
            </v-btn>
             -->

            <v-btn color="red" icon>
                <v-divider vertical class="mr-5"></v-divider>
                <v-icon @click="logout()">mdi-logout</v-icon>
            </v-btn>
            </v-app-bar>

            <v-main>
                <router-view></router-view>
            </v-main>
            <v-navigation-drawer v-model="drawer" absolute temporary>
                <v-list nav dense>
                    <v-list-item-group
                    v-model="group"
                    active-class="black--text text--accent-4"
                    >
                    <v-list-item @click="$router.push(permiso.permiso.ruta)" v-for="(permiso, index) in rutas_disponibles" :key="index">
                        <v-list-item-icon>
                        <v-icon>{{ permiso.permiso.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ permiso.permiso.nombre }}</v-list-item-title
                        >
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
            
           
    </v-app>

</template>
<script>
import Perfil from '../components/Perfil'
//import BuscarInformacionEmpresa from '../components/comunes/BuscarInformacionEmpresa'
import Buscador from '../components/buscador/Buscador.vue'

export default {
    components: {
       Buscador,
       Perfil,
      // BuscarInformacionEmpresa
    },
    data(){
        return{
            drawer: false,
            group: null,
            buscador: false,
            rutas_disponibles: [
                {
                    permiso: {
                        nombre: 'Inicio',
                        ruta: {name: 'home_rol' },
                        icon: 'mdi-home'
                    },
                    value: true,
                }
            ],
            permiso_buscador: false,
            permisos_disponibles: [
                {
                    nombre: 'Digitalizar documentos',
                    ruta: {name: 'digitalizar'},
                    icon: 'mdi-upload',
                    imagen: require('../assets/digitalizar.png'),
                },
                {
                    nombre: 'Bandeja de entrada',
                    ruta: this.$store.state.dataUser.dataUser.rango == 'Supervisor'? {name:'home_supervisor'}: {name:'inicio'} ,
                    icon: 'mdi-email',
                    imagen: require('../assets/bandeja_entrada.png'),
                },
                {
                    nombre: 'Estado de documentos',
                    ruta: {name: 'buscar_estado_documento' },
                    icon: 'mdi-progress-check',
                    imagen: require('../assets/estado_documento.png')
                },
                {
                    nombre: 'Buscar documentos',
                    ruta: {name: 'buscar_documentos' },
                    icon: 'mdi-magnify',
                    imagen: require('../assets/buscar.png')
                },
                {
                    nombre: 'Iniciar ciclo',
                    ruta: {name: 'iniciar_caso'},
                    icon: 'mdi-book-plus-multiple-outline',
                    imagen: require('../assets/iniciar.png')
                },
                {
                    nombre: 'Inventario documental',
                    ruta: {name: 'inventario_documental'},
                    icon: 'mdi-archive-arrow-up-outline',
                    imagen: require('../assets/inventario.png')
                },
                {
                    nombre: 'Empalme de plataformas',
                    ruta: {name: 'empalme'},
                    icon: 'mdi-folder-move-outline',
                    imagen: require('../assets/empalme.png')
                },
                {
                    nombre: 'Reportes de digitalización',
                    ruta: {name: 'reportes_v1'},
                    icon: 'mdi-database-eye',
                    imagen: require('../assets/report.png')
                }
            ],
        }
    },
    created(){
        console.log('Hosa')
        this.consultar_permisos()
        this.consultar_datos_sector();

        console.log(this.permisos_disponibles[7]);

    },
    methods: {
       async logout(){

        

           //Colocar permiso de almacenar produccion
           if(this.$store.state.dataUser.dataUser.nombre_sector.toLowerCase()=='archivadores' || this.$store.state.dataUser.dataUser.nombre_sector.toLowerCase()=='archivador')
            {
                let response = await this.$store.dispatch('SET_API_DOCUMENTOS_SUBIDOS')
                console.log(response)
                if(response){
                    this.$store.commit(
                        "exitoso",
                            "Su producción antes de salirse de la plataforma era: "+ this.$store.state.documentos.documentos_subidos+ " Los datos han sido almacenados en la plataforma"
                        );

                 this.$store.commit('RESET_DOCUMENTOS_SUBIDOS',0)   
                        
                }else{
                        this.$store.commit('error',"Su producción antes de salirse de la plataforma era: "+ this.$store.state.documentos.documentos_subidos+ " Los datos NO han sido almacenados en la plataforma")
                }
               
            }
            this.$router.push('/')
       },
       async consultar_datos_sector(){
           try {
                let response = await this.$store.dispatch('llamado_get',
        
                {url: 'https://siett.educandote.co/parse/classes/prueba01Sector/'+this.$store.state.dataUser.dataUser.sector,tipo_header:'parse'})
                this.$store.state.dataUser.dataUser.nombre_sector = response.data.nombre
                console.log( this.$store.state.dataUser.dataUser)
           } catch (error) {
               console.log(error)
           }
            
       },
        async consultar_permisos(){
            console.log(this.$store.state.dataUser.dataUser)
            let buscar = JSON.stringify({"sector":this.$store.state.dataUser.dataUser.sector})
            buscar = encodeURIComponent(buscar)
            let response = await this.$store.dispatch('llamado_get',
            {url: 'https://siett.educandote.co/parse/classes/prueba01PermisosUsuario?where='+buscar,tipo_header:'parse'})
            console.log(response)
            this.$store.state.dataUser.permisos_usuario = [];
            let index = 0
            if(response.data.results.length>0){
                Object.keys(response.data.results[0]).forEach( (key) => {
                
                
                    if(key!='objectId' && key!='createdAt' && key!='updatedAt' && key!='sector'){
                        
                            
                        if(key!='permiso_2' && key!='permiso_4'){
                            console.log(key)
                            console.log(response.data.results[0][key][this.$store.state.dataUser.dataUser.rango.toLowerCase()])
                            if(response.data.results[0][key][this.$store.state.dataUser.dataUser.rango.toLowerCase()]){

                                    if(key=='permiso_1'){ //El permiso 1 habilita 2 modulos 1 de empalme y otro de digitalizacion por primera vez
                                        this.$store.state.dataUser.permisos_usuario.push(
                                            {
                                                permiso: { ...this.permisos_disponibles[6], permiso: key  },
                                                value: response.data.results[0][key][this.$store.state.dataUser.dataUser.rango.toLowerCase()]
                                            }
                                        )
                                    }
                                    this.$store.state.dataUser.permisos_usuario.push({
                                        permiso: { ...this.permisos_disponibles[index], permiso: key  },
                                        value: response.data.results[0][key][this.$store.state.dataUser.dataUser.rango.toLowerCase()]
                                    })
                                }
                        }else{
                            console.log(response.data.results[0][key].permiso)
                            if(response.data.results[0][key].permiso){
                                this.$store.state.dataUser.permisos_usuario.push({
                                    permiso: { ...this.permisos_disponibles[index], permiso: key  },
                                    value: response.data.results[0][key][this.$store.state.dataUser.dataUser.rango.toLowerCase()]
                                })
                            }
                        }
                        
                        
                        index++
                    }
                })
                 console.log(this.$store.state.dataUser.permisos_usuario)
                 for (let index = 0; index < this.$store.state.dataUser.permisos_usuario.length; index++) {
                    if(this.$store.state.dataUser.permisos_usuario[index].permiso.permiso=='permiso_4'){
                        this.permiso_buscador = true;

                         for (let index2 = 0; index2 <  this.$store.state.dataUser.permisos_usuario[index].value.length; index2++) {
                            
                            this.$store.state.dataUser.permisos_buscador[this.$store.state.dataUser.permisos_usuario[index].value[index2].nombre] = this.$store.state.dataUser.permisos_usuario[index].value[index2].value
                            
                        }
                      
                    }
                    
                    if(this.$store.state.dataUser.permisos_usuario[index].permiso.permiso=='permiso_2'){
                        
                        for (let index2 = 0; index2 <  this.$store.state.dataUser.permisos_usuario[index].value.length; index2++) {
                            
                            this.$store.state.dataUser.permisos_caso[this.$store.state.dataUser.permisos_usuario[index].value[index2].nombre] = this.$store.state.dataUser.permisos_usuario[index].value[index2].value
                            
                        }
                        
                    }
                    if(this.$store.state.dataUser.permisos_usuario[index].permiso.permiso=='permiso_1'){
                        this.$store.state.dataUser.permisos_caso.Digitalizar = true
                    }
                    
                }
                console.log( this.$store.state.dataUser.permisos_caso)
               
                this.rutas_disponibles = [...this.rutas_disponibles,  ...this.$store.state.dataUser.permisos_usuario,]
            }


            this.consultar_permisos_modulos_privados();

            console.log('Consultando permisos');
            console.log( this.$store.state.dataUser.permisos_buscador);
            
        },
        async consultar_permisos_modulos_privados(){
            console.log('xs')
            try {
                let buscar = JSON.stringify({"userId":this.$store.state.dataUser.dataUser.objectId})
                buscar = encodeURIComponent(buscar);
                const response = await this.$store.dispatch('llamado_get',{
                    url: "https://siett.educandote.co/parse/classes/V1_PRIVATE_MODULES?where="+buscar,
                    tipo_header: "parse",
                });
                console.log(response);

                for (let index = 0; index < response.data.results.length; index++) {
                    this.rutas_disponibles.push({permiso: JSON.parse(response.data.results[index].module), value: true });
                    this.$store.dataUser.state.private_modules.push(JSON.parse(response.data.results[index]) )
                }

                console.log(this.rutas_disponibles)

                
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>